.additional-content-ac {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 60px;
  margin: 0 auto;
  max-width: 1400px; /* Adjusted for larger screens */
  margin-bottom: 80px;
  margin-top: 60px;
}

.promo-section-pg {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: #f9f9f9;  #05192D;*/
  background-color: #fff;
  padding: 30px; /* Increased padding for larger screens */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  margin: 0 auto;
  max-width: 1200px; /* Adjusted for larger screens */
  margin-top: 80px;
  width: 100%;
  flex-wrap: wrap;
}

.promo-content-pg {
  flex: 1;
  margin-left: 30px; /* Increased margin for larger screens */
  max-width: 55%; /* Adjusted for larger screens */
color: #343a40;
  
}

.promo-content-pg ul li {
  font-size: 1.2rem; /* Slightly larger font size */
  margin-bottom: 10px;
}

.roadmap-image-pg {
  width: 40%;
  max-width: 600px;
  height: auto;
  margin-right: 30px; /* Increased margin for larger screens */
  border-radius: 7px;
}

.promo-buttons-pg {
  display: flex;
  gap: 30px; /* Increased gap between buttons for larger screens */
  justify-content: center;
  margin-top: 40px;
}

.promo-button-pg {
  padding: 15px 30px; /* Larger padding for buttons on larger screens */
  font-size: 1.2rem; /* Larger font size for buttons */
  border: none;
  border-radius: 4px;
  cursor: pointer;

}

.demo-button-pg {
  background-color: rgb(255, 147, 30);
  color: black;
}

.get-started-button-pg {
  background-color: #343a40;
  color: white;
}

.content-item-ac {
  display: flex;
  align-items: center;
  gap: 50px; /* Increased gap for larger screens */
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 1400px; /* Adjusted for larger screens */
  margin: 0 auto;
}

.content-image-ac {
  width: 500px; /* Larger image size for larger screens */
  height: auto;
}

.content-text-ac {
  max-width: 800px;
  font-size: 2.5rem; /* Larger text for larger screens */
  line-height: 1.8;
}

.content-text-ac h3 {
  font-size: 2.3rem; /* Larger heading for larger screens */
}

.content-text-ac p {
  font-size: 1.8rem; /* Larger paragraph text for larger screens */
}

/* Responsive styles */
@media (max-width: 1024px) { 
  .promo-section-pg {
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding: 15px;
      margin: 20px; /* Margins on all sides, including right */
  }

  .roadmap-image-pg {
      width: 70%; /* Further reduce width */
      max-width: 250px;
      margin: 0 0 20px 0;
  }

  .promo-content-pg {
      max-width: 90%;
      margin: 0 10px; /* Added right margin */
  }

  .promo-content-pg h1 {
      font-size: 1.6rem; /* Further reduced font size */
  }

  .promo-content-pg p {
      font-size: 0.9rem;
  }

  .content-item-ac {
      flex-direction: column;
      text-align: center;
      gap: 10px;
  }

  .content-image-ac {
      width: 70%;
      max-width: 200px;
  }

  .content-text-ac {
      max-width: 80%;
      font-size: 1.3rem; /* Smaller font */
  }

  .content-text-ac h3 {
      font-size: 1.4rem;
  }

  .content-text-ac p {
      font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .promo-section-pg {
      
      width: 95%;
      padding: 8px;
        margin: 10px;
  }

  .promo-content-pg h1 {
      font-size: 1.4rem; /* Further reduced */
  }

  .promo-content-pg p {
      font-size: 0.8rem;
  }

  .content-item-ac {
      flex-direction: column;
      gap: 10px;
      text-align: center;
  }

  .content-item-ac {
    flex-direction: row;
    gap: 20px;
    text-align: left;
}

.content-image-ac {
    width: 40%;
    max-width: 250px;
}

.content-text-ac {
    max-width: 55%;
    font-size: 1.6rem;
}

.content-text-ac h3 {
    font-size: 1.6rem;
}

.content-text-ac p {
    font-size: 1.1rem;
}
}

@media (max-width: 480px) {
  .promo-section-pg {
      padding: 5px;
      margin: 10px;
      width: 90%;
  }

  .promo-content-pg h1 {
      font-size: 1.2rem; /* Even smaller */
  }

  .promo-content-pg p {
      font-size: 0.7rem; /* Further reduced font size */
  }

  .content-item-ac {
    flex-direction: row;
    gap: 15px;
}

.content-image-ac {
    width: 40%;
    max-width: 200px;
}

.content-text-ac {
    max-width: 55%;
    font-size: 1.4rem;
}

.content-text-ac h3 {
    font-size: 1.4rem;
}

.content-text-ac p {
    font-size: 1rem;
}



  .promo-buttons-pg {
      flex-direction: column;
      gap: 8px;
  }
}


